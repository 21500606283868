import { extendTheme } from '@chakra-ui/react'


export const theme = extendTheme({
  colors: {
    bg: {
      screen: '#f6f9fb',
      surface: '#fefffe'
    },
    text: {
      muted: '#4A5568'
    }
  }
})
