import { Image as ChakraImage, type ImageProps } from '@chakra-ui/react'

declare global {
  interface Window {
    prerelease?: unknown
  }
}

function srcWithPrerelease(src: string | undefined): string | undefined {
  if (!src) {
    return src
  }

  const url = new URL(src, window.location.href)
  if (url.hostname === window.location.hostname && window.prerelease && typeof window.prerelease === 'object') {
    for (const [ name, value ] of Object.entries(window.prerelease)) {
      url.searchParams.set(name, value)
    }
  }

  return url.hostname === window.location.hostname ? `${url.pathname}${url.search}${url.hash}` : url.toString()
}


export function Image({ src, ...props }: ImageProps) {
  return <ChakraImage src={srcWithPrerelease(src)} {...props} />
}
