import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApplication } from './useApplication'


export function useApplicationAwareNavigate() {
  const { id } = useApplication()
  const reactRouterNavigate = useNavigate()

  const navigate = useCallback((to: string) => {
    reactRouterNavigate(`/${id}${to}`)
  }, [ reactRouterNavigate, id ])

  return { navigate }
}
