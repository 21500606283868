import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
  Text
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { FormEvent, useCallback, useMemo, useState } from 'react'
import logo from 'cleverev-whitelabel/console/default/assets/Logo_color.svg'
import { signIn } from '../../../../api/users'
import { useAuthentication } from '../../../../hooks/useAuthentication'
import { useTitle } from '../../../../hooks/useTitle'
import { Alert } from '../../../../components/Feedback'
import { Image } from '../../../../components/Image'
import { useNavigate } from 'react-router-dom'


export function SignInScreen() {
  const { t } = useTranslation('translation', { keyPrefix: 'unauthenticated.sign_in_screen' })
  const { signInAs } = useAuthentication()
  const navigate = useNavigate()

  useTitle(t('document_title'))

  // Find the remembered email from the last sign-in
  const rememberedEmail = useMemo(() => localStorage.getItem('remembered_email'), [])

  // Form state
  const [ email, setEmail ] = useState(rememberedEmail ?? '')
  const [ password, setPassword ] = useState('')
  const [ rememberMe, setRememberMe ] = useState(true)

  // Sign in mutation
  const signInMutation = useMutation({
    mutationKey: [ 'signin' ],
    mutationFn: () => signIn(email, password),
    onSuccess: data => signInAs(data.user)
  })

  // Handle submitting the sign-in form
  const handleSubmit = useCallback((event: FormEvent) => {
    event.preventDefault()
    if (rememberMe) {
      localStorage.setItem('remembered_email', email)
    } else {
      localStorage.removeItem('remembered_email')
    }

    signInMutation.mutate()
  }, [ email, rememberMe, signInMutation ])

  return (
    <Container maxW="lg" py={24} px={8}>
      <Stack spacing="8">

        <Stack spacing="6" align="center">
          <Image src={logo} width={16} height={16} />
          <Stack spacing={3} textAlign="center">
            <Heading size="lg">
              {t('header.title')}
            </Heading>
            <HStack justify="center">
              <Text fontSize="md" color="text.muted">
                {t('header.no_account_prompt')}
              </Text>
              <Button variant="link" size="sm" colorScheme="blue" onClick={() => navigate('/signup')}>
                {t('header.go_to_sign_up')}
              </Button>
            </HStack>
          </Stack>
        </Stack>

        <Box py={8} px={10} bg="bg.surface" boxShadow="md" borderRadius="md">
          <Stack spacing="6" as="form" onSubmit={handleSubmit}>

            {signInMutation.isError && (
              <Alert status="error" description={t('form.error')} />
            )}

            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email">{t('form.fields.email')}</FormLabel>
                <Input
                  id="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  autoFocus={!rememberedEmail}
                  isRequired
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="password">{t('form.fields.password')}</FormLabel>
                <Input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  isRequired
                  autoFocus={!!rememberedEmail}
                />
              </FormControl>
            </Stack>

            <HStack justify="space-between">
              <Checkbox
                isChecked={rememberMe}
                onChange={event => setRememberMe(event.target.checked)}
              >
                {t('form.fields.remember_me')}
              </Checkbox>
              <Button variant="link" size="sm" colorScheme="blue" onClick={() => navigate('/password-reset')}>
                {t('form.buttons.go_to_pass_reset')}
              </Button>
            </HStack>

            <Button
              type="submit"
              colorScheme="blue"
              loadingText={t('form.buttons.sign_in.pending')}
              isLoading={signInMutation.isPending || signInMutation.isSuccess}
            >
              {t('form.buttons.sign_in.idle')}
            </Button>

          </Stack>
        </Box>

      </Stack>
    </Container>
  )
}
