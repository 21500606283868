import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { listApplications } from '../../../api/applications'
import { useApplication } from '../../../hooks/useApplication'


export function SelectApplications() {
  const selectedApplication = useApplication()

  // List applications query
  const applicationsQuery = useQuery({
    queryKey: [ 'application-list' ],
    queryFn: () => listApplications({ skip: 0, take: 100 }) // TODO fix take 100
  })

  return (
    <Menu isLazy>
      {<MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        variant="ghost"
        isDisabled={applicationsQuery.isLoading}
      >
        {selectedApplication.name}
      </MenuButton>}
      <MenuList maxHeight={300} overflowX="auto" zIndex={2000}>
        <MenuOptionGroup value={selectedApplication.id} type="radio" onChange={id => location.assign(`${location.origin}/${id as string}/namespaces`)}>
          {applicationsQuery.data?.applications.map(application => (
            <MenuItemOption
              key={application.id}
              value={application.id}
            >
              {application.name}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
