import { Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Table, TableHead, TableHeadCell } from '../../../../components/Table'
import { useTablePagination } from '../../../../components/Table/hooks'
import { listNamespaces } from '../../../../api/namespaces'
import { NamespacesTableRow } from './NamespacesTableRow'


export function NamespacesTable() {
  const { t } = useTranslation()
  const pagination = useTablePagination()

  const query = useQuery({
    queryKey: [ 'namespaces', 'list' ],
    queryFn: () => listNamespaces(pagination.api),
    select: data => {
      return { data: data.namespaces, count: data.count }
    }
  })

  return (
    <Table
      TableHead={
        <TableHead>
          <TableHeadCell>{t('Name')}</TableHeadCell>
          <TableHeadCell>{t('ID')}</TableHeadCell>
          <TableHeadCell>{t('Currency')}</TableHeadCell>
          <TableHeadCell>{t('Created At')}</TableHeadCell>
          <TableHeadCell>{t('Updated At')}</TableHeadCell>
          <TableHeadCell isMenu />
        </TableHead>
      }
      source={{ query }}
      title={t('Namespaces')}
      TableEmpty={<Text>{t('There are no namespaces.')}</Text>}
      renderItem={({ item: namespace }) => <NamespacesTableRow namespace={namespace} />}
      pagination={pagination}
    />
  )
}
