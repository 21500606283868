import { CreateNamespacePropertiesData, Namespace, NamespaceProperties } from '../types'
import { api, ApiResult, Pagination } from './api'


// GENERAL


export async function listNamespaces(pagination: Pagination) {
  const result = await api.get<ApiResult<{ namespaces: Namespace[]; count: number }>>(`/api/hub/namespaces?skip=${pagination.skip}&take=${pagination.take}`)

  return result.data
}

export async function createNamespace(name: string, properties: CreateNamespacePropertiesData) {
  const result = await api.post<ApiResult<{ namespaceId: string }>>('/api/hub/namespaces', {
    name,
    properties
  })

  return result.data
}

export async function suspendNamespace(namespaceId: string) {
  const result = await api.post<ApiResult<{ namespaceId: string }>>(`/api/hub/namespaces/${namespaceId}/suspend`)

  return result.data
}

export async function assignNamespaceTo({ namespaceId, userId }: { namespaceId: string; userId: string }) {
  const result = await api.post<ApiResult>(`/api/hub/namespaces/${namespaceId}/assign-to`, { userId })

  return result.data
}

export async function getNamespace(namespaceId: string) {
  const result = await api.get<ApiResult<{ namespace: Namespace }>>(`/api/hub/namespaces/${namespaceId}`)

  return result.data
}

interface UpdateNamespaceProperties {
  currency?: NamespaceProperties['currency'] | null
  deposit?: NamespaceProperties['deposit'] | null
  alert?: NamespaceProperties['alert'] | null
  szamlazz?: NamespaceProperties['szamlazz'] | null
}

export async function updateNamespaceProperties(properties: UpdateNamespaceProperties) {
  const result = await api.post<ApiResult<{ namespace: Namespace }>>('/api/namespaces/selected/properties', {
    properties
  })

  return result.data
}
