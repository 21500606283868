import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { Eye, EyeClosed } from 'iconoir-react'
import { FormEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useFormField } from '../../../../../hooks/useFormField'
import { changePassword } from '../../../../../api/users'
import { Alert } from '../../../../../components/Feedback'


export function ChangePasswordSection() {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.authentication.change_password' })

  // Current password form field
  const currentPasswordField = useFormField({
    initialValue: '',
    schema: z.string()
      .min(1, t('fields.current_password.error.required'))
  })

  // New password form field
  const newPasswordField = useFormField({
    initialValue: '',
    schema: z.string()
      .min(1, t('fields.new_password.error.required'))
      .min(8, t('fields.new_password.error.weak'))
  })

  // Confirm password form field
  const confirmPasswordField = useFormField({
    initialValue: '',
    schema: z.string()
      .refine(value => value === newPasswordField.value, t('fields.confirm_password.error.mismatch'))
  })

  // Should show password
  const [ show, setShow ] = useState(false)

  // Change password mutation
  const mutation = useMutation({
    mutationKey: [ 'user', 'change-password' ],
    mutationFn: () => changePassword(currentPasswordField.value, newPasswordField.value),
    onSuccess: () => {
      // @ts-expect-error focused element won't be unfocusable
      document.activeElement?.blur()
      // Reset form
      currentPasswordField.reset()
      newPasswordField.reset()
      confirmPasswordField.reset()
    }
  })

  // Handle submit
  const handleSubmit = useCallback((event: FormEvent) => {
    event.preventDefault()
    mutation.mutate()
  }, [ mutation ])

  return (
    <Card as="form" onSubmit={handleSubmit}>

      <CardHeader>
        <Heading size="md">{t('title')}</Heading>
      </CardHeader>

      <CardBody as={Flex} flexDirection="column" gap={4} maxW="50%">

        {mutation.isError && (
          <Alert status="error" description={t('feedback.failure')} />
        )}

        {mutation.isSuccess && (
          <Alert status="success" description={t('feedback.success')} />
        )}

        {/* CURRENT PASSWORD */}

        <FormControl isRequired isInvalid={currentPasswordField.shouldShowError}>
          <FormLabel htmlFor="current-password">{t('fields.current_password.label')}</FormLabel>
          <Input
            id="current-password"
            type="password"
            autoComplete="current-password"
            value={currentPasswordField.value}
            onChange={e => currentPasswordField.setValue(e.target.value)}
            onBlur={currentPasswordField.handleBlur}
            maxWidth={450}
          />
          <FormErrorMessage>{currentPasswordField.error}</FormErrorMessage>
        </FormControl>

        {/* NEW PASSWORD */}

        <FormControl isRequired isInvalid={newPasswordField.shouldShowError}>
          <FormLabel htmlFor="new-password">{t('fields.new_password.label')}</FormLabel>
          <InputGroup size="md" maxWidth={450}>
            <Input
              id="new-password"
              type={show ? 'text' : 'password'}
              autoComplete="new-password"
              value={newPasswordField.value}
              onChange={e => newPasswordField.setValue(e.target.value)}
              onBlur={newPasswordField.handleBlur}
            />
            <InputRightElement>
              <IconButton
                aria-label={show ? t('buttons.password_toggle.hide') : t('buttons.password_toggle.show')}
                size="sm"
                onClick={() => setShow(prevState => !prevState)}
                icon={show ? <Eye /> : <EyeClosed />}
                variant="ghost"
              />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{newPasswordField.error}</FormErrorMessage>
        </FormControl>

        {/* CONFIRM PASSWORD */}

        <FormControl isRequired isInvalid={confirmPasswordField.shouldShowError}>
          <FormLabel htmlFor="confirm-password">{t('fields.confirm_password.label')}</FormLabel>
          <Input
            id="confirm-password"
            type={show ? 'text' : 'password'}
            autoComplete="new-password"
            value={confirmPasswordField.value}
            onChange={e => confirmPasswordField.setValue(e.target.value)}
            onBlur={confirmPasswordField.handleBlur}
            maxWidth={450}
          />
          <FormErrorMessage>{confirmPasswordField.error}</FormErrorMessage>
        </FormControl>

      </CardBody>

      <CardFooter>

        <Button
          type="submit"
          colorScheme="blue"
          isLoading={mutation.isPending}
          isDisabled={currentPasswordField.isInvalid || newPasswordField.isInvalid || confirmPasswordField.isInvalid}
          loadingText={t('buttons.submit.pending')}
        >
          {t('buttons.submit.idle')}
        </Button>

      </CardFooter>

    </Card>
  )
}
