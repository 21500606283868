import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle
} from '@chakra-ui/react'


interface ControlledStepperProps {
  steps: { title: string; description?: string }[]
  activeStep: number
}

export function ControlledStepper({ steps, activeStep }: ControlledStepperProps) {
  return (
    <Stepper index={activeStep}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box flexShrink={0}>
            <StepTitle>{step.title}</StepTitle>
            {step.description && (
              <StepDescription>{step.description}</StepDescription>
            )}
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  )
}
