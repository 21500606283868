import { useContext } from 'react'
import { UserContext } from '../contexts/UserContext'


export function useUser() {
  const context = useContext(UserContext)
  if (!context) throw new Error('The useUser() hook was used outside of UserContextProvider.')
  if (!context.user) throw new Error('The useUser() hook was used without authentication.')

  return context.user
}
