import { User, UserProperties } from '../types'
import { api, ApiResult, Pagination } from './api'


// GENERAL


export async function listUsers(pagination: Required<Pagination>) {
  const result = await api.get<ApiResult<{ users: User[]; count: number }>>(`/api/users?skip=${pagination.skip}&take=${pagination.take}`)

  return result.data
}


// UNAUTHENTICATED


export async function signIn(email: string, password: string) {
  const result = await api.post<ApiResult<{ user: User }>>('/api/hub/users/signin', {
    email,
    password
  })

  return result.data
}

export async function signUp(email: string, password: string, properties: Pick<UserProperties, 'fullname'>) {
  const result = await api.post<ApiResult<{ user: User }>>('/api/hub/users/signup', {
    email,
    password,
    fullname: properties.fullname
  })

  return result.data
}

export async function clientSignUp(email: string, password: string, properties: Pick<UserProperties, 'fullname'>) {
  const result = await api.post<ApiResult<{ user: User }>>('/api/hub/users/client-signup', {
    email,
    password,
    fullname: properties.fullname
  })

  return result.data
}

export async function getEmailAvailability(email: string) {
  const result = await api.post<ApiResult<{ available: boolean }>>('/api/users/email-check', {
    email
  })

  return result.data
}

export async function requestPasswordReset(email: string) {
  const result = await api.post<ApiResult>('/api/users/password/initiate-reset', {
    email
  })

  return result.data
}

export async function confirmPasswordReset(token: string, password: string) {
  const result = await api.post<ApiResult>('/api/users/password/reset', {
    token,
    password
  })

  return result.data
}

export async function verifyEmail(token: string) {
  const result = await api.post<ApiResult>('/api/users/verify', {
    token
  })

  return result.data
}


// SELF


export async function getSelf() {
  const result = await api.get<ApiResult<{ user: User }>>('/api/hub/users/self')

  return result.data
}

export async function updateSelfProperties(properties: Partial<Pick<UserProperties, 'fullname' | 'address'>>) {
  const result = await api.post('/api/users/self/properties', {
    properties
  })

  return result.data
}

export async function signout() {
  const result = await api.get<ApiResult>('/api/hub/users/self/signout')

  return result.data
}

export async function changePassword(oldPassword: string, newPassword: string) {
  const result = await api.post<ApiResult>('/api/users/self/change-password', {
    oldPassword,
    newPassword
  })

  return result.data
}

export async function resendVerificationEmail() {
  const result = await api.post<ApiResult>('/api/users/self/resend-verification-email', {})

  return result.data
}

export async function requestDeletion(password: string) {
  const result = await api.post<ApiResult>('/api/users/self/request-deletion', {
    password
  })

  return result.data
}
