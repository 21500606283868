import { ReactElement } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '@chakra-ui/react'


interface NavItemProps {
  icon: ReactElement
  route: string
  title: string
}

export function NavItem({ title, icon, route }: NavItemProps) {
  const navigate = useNavigate()

  // Check if the nav item is active
  const { pathname } = useLocation()
  const path = `/${pathname.split('/')[1]}`
  const isActive = path === '/' ? route === '/' : route.startsWith(path)

  return (
    <Button
      variant={isActive ? undefined : 'ghost'}
      role="listitem"
      justifyContent="left"
      leftIcon={icon}
      onClick={() => navigate(route)}
    >
      {title}
    </Button>
  )
}
