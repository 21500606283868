import { useQuery } from '@tanstack/react-query'
import { createContext, ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getApplication } from '../api/applications'
import { type Application } from '../types'


interface IApplicationContext {
  isLoading: boolean
  application?: Application
}

export const ApplicationContext = createContext<IApplicationContext | null>(null)

export function ApplicationContextProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate()

  // Fetch the application
  const applicationQuery = useQuery({
    queryKey: [ 'application' ],
    queryFn: () => getApplication(),
    staleTime: Number.POSITIVE_INFINITY,
    retry: false
  })

  // TODO actually implement this.
  // If the application cannot be fetched, show an error to the user.
  useEffect(() => {
    if (applicationQuery.isError) navigate('/')
  }, [ applicationQuery.isError, navigate ])

  // Loading state, TODO use some actual loading UI
  if (!applicationQuery.data?.application) return null

  return (
    <ApplicationContext.Provider value={{
      isLoading: applicationQuery.isPending,
      application: applicationQuery.data.application
    }}>
      {children}
    </ApplicationContext.Provider>
  )
}
