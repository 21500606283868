import { useEffect, useRef } from 'react'
import { config } from 'cleverev-whitelabel/console/default/config'


export function useTitle(title: string, prevailOnUnmount = false, divider = '|') {
  const defaultTitleComponent = useRef(document.title)
  const defaultTitle = config.pageTitle

  useEffect(() => {
    document.title = defaultTitle ? `${title} ${divider} ${defaultTitle}` : title
  }, [ title, defaultTitle, prevailOnUnmount, divider ])

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitleComponent.current
    }
  }, [ prevailOnUnmount ])

  return document.title
}
