import { Thead, Tr } from '@chakra-ui/react'
import { ReactNode } from 'react'


export interface TableHeadProps {
  children?: ReactNode
}

export function TableHead({ children }: TableHeadProps) {
  return (
    <Thead>
      <Tr>
        {children}
      </Tr>
    </Thead>
  )
}
