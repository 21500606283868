import { useTranslation } from 'react-i18next'
import { useTitle } from '../../hooks'


export default function NotFoundScreen() {
  const { t } = useTranslation()
  useTitle(t('pages.notfound'))

  return (
    <main>
      <p>{t('general.pageNotFound')}</p>
    </main>
  )
}
