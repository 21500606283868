import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Spinner,
  Table as ChakraTable,
  TableContainer,
  Tbody
} from '@chakra-ui/react'
import { UseQueryResult } from '@tanstack/react-query'
import { Fragment, ReactElement, useMemo } from 'react'
import { TableControls } from './TableControls'
import type { PaginationControl } from './types'


export interface TableProps<TItem extends { id: string | number }> {
  // Data source (useQuery or data array)
  source: {
    query: UseQueryResult<{ data: TItem[]; count: number }>
  } | {
    data: TItem[]
  }
  // Pagination
  pagination?: PaginationControl
  // Visuals
  title: string
  TableHead: ReactElement | null
  TableEmpty?: ReactElement | null
  renderItem: (info: { item: TItem; index: number }) => ReactElement | null
}

export function Table<TItem extends { id: string | number }>({
  source,
  title,
  pagination,
  renderItem,
  TableHead,
  TableEmpty
}: TableProps<TItem>) {

  const isLoading = 'query' in source ? source.query.isLoading : false
  const isPlaceholder = 'query' in source ? source.query.isPlaceholderData : false

  const data = useMemo(() => {
    return 'query' in source ? source.query.data?.data ?? [] : source.data
  }, [ source ])

  const itemCount = useMemo(() => {
    return 'query' in source ? source.query.data?.count ?? 0 : source.data.length
  }, [ source ])

  return (
    <Card>
      <CardHeader>
        <HStack spacing={4} alignItems="center">
          <Heading size="md">
            {title}
          </Heading>
          {isPlaceholder && (
            <Spinner size="sm" />
          )}
        </HStack>
      </CardHeader>
      <CardBody>
        {isLoading ? (
          <Spinner />
        ) : data.length === 0 ? (
          <Fragment>
            {TableEmpty}
          </Fragment>
        ) : (
          <Fragment>
            <TableContainer>
              <ChakraTable style={{ tableLayout: 'fixed' }}>
                {TableHead}
                <Tbody>
                  {data.map((item, index) => (
                    <Fragment key={item.id}>
                      {renderItem({ item, index })}
                    </Fragment>
                  ))}
                </Tbody>
              </ChakraTable>
            </TableContainer>
            {pagination && (
              <TableControls pagination={pagination} itemCount={itemCount} />
            )}
          </Fragment>
        )}
      </CardBody>
    </Card>
  )
}
