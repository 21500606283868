import {
  Button,
  ModalBody,
  ModalFooter,
  Flex,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { assignNamespaceTo } from '../../../../../api/namespaces'
import { Alert } from '../../../../../components/Feedback'


interface StepAssignUserProps {
  namespaceId: string
  close: () => void
}

export function StepAssignUser({ namespaceId, close }: StepAssignUserProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'namespaces.create.steps.assign_user' })
  const [ userId, setUserId ] = useState('')
  const [ isUserIdTouched, setIsUserIdTouched ] = useState(false)

  const userIdError = useMemo(() => {
    const schema = z.string()
      .min(1, t('user_id.errors.required'))
      .uuid(t('user_id.errors.invalid_format'))
    const result = schema.safeParse(userId)
    if (!result.success) return result.error.errors[0].message
  }, [ userId, t ])

  const assignNamespaceMutation = useMutation({
    mutationKey: [ 'namespaces', namespaceId, 'assign-to', userId ],
    mutationFn: () => assignNamespaceTo({ namespaceId, userId }),
    onSuccess: _ => close()
  })

  return (
    <Fragment>
      <ModalBody as={Flex} direction="column" gap={8}>

        {assignNamespaceMutation.isError && (
          <Alert status="error" description={t('alert')} />
        )}

        <FormControl isInvalid={isUserIdTouched && !!userIdError} isRequired>
          <FormLabel>{t('user_id.label')}</FormLabel>
          <Input
            value={userId}
            onChange={e => setUserId(e.target.value)}
            onBlur={() => setIsUserIdTouched(true)}
            placeholder={t('user_id.placeholder')}
          />
          {userIdError && (
            <FormErrorMessage>{userIdError}</FormErrorMessage>
          )}
          <FormHelperText>{t('user_id.description')}</FormHelperText>
        </FormControl>

      </ModalBody>
      <ModalFooter gap={4}>
        <Button onClick={close} variant="ghost">
          {t('buttons.cancel')}
        </Button>
        <Button
          onClick={() => assignNamespaceMutation.mutate()}
          isDisabled={!!userIdError}
          isLoading={assignNamespaceMutation.isPending}
          loadingText={t('buttons.assign')}
          colorScheme="blue"
        >
          {t('buttons.assign')}
        </Button>
      </ModalFooter>
    </Fragment>
  )
}
