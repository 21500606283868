import { ButtonGroup, Flex, IconButton, useEditableControls } from '@chakra-ui/react'
import { Check, EditPencil, Xmark } from 'iconoir-react'


export function EditableControls() {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps
  } = useEditableControls()

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton
        aria-label="submit"
        size="md"
        icon={<Check width={20} height={20} />}
        {...getSubmitButtonProps()}
      />
      <IconButton
        aria-label="cancel"
        size="md"
        icon={<Xmark width={20} height={20} />}
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton
        aria-label="edit"
        size="md"
        icon={<EditPencil width={20} height={20} />}
        {...getEditButtonProps()}
      />
    </Flex>
  )
}
