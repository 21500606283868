import { Button, Card, CardBody, CardFooter, CardHeader, Flex, Heading } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../../../../hooks/useUser'
import { Alert } from '../../../../../components/Feedback'
import { resendVerificationEmail } from '../../../../../api/users'
import { useToast } from '../../../../../hooks/useToast'


export function VerifyAccountSection() {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.account.verify' })
  const user = useUser()
  const toast = useToast()

  // Re-send verification email mutation
  const mutation = useMutation({
    mutationKey: [ 'user', 'resend-verification-email' ],
    mutationFn: () => resendVerificationEmail(),
    onSuccess: () => {
      toast.info(t('feedback.success'))
    }
  })

  // Render only if the user is not verified
  if (user.emailVerified) return null

  return (
    <Card>

      <CardHeader>
        <Heading size="md">{t('title')}</Heading>
      </CardHeader>

      <CardBody as={Flex} flexDirection="column" gap={4} maxW="50%">

        {mutation.isError && (
          <Alert status="error" description={t('feedback.failure')} />
        )}

        <Alert status="warning" description={t('warning')} />

      </CardBody>

      <CardFooter>

        <Button
          onClick={() => mutation.mutate()}
          isLoading={mutation.isPending}
          loadingText={t('buttons.submit.pending')}
        >
          {t('buttons.submit.idle')}
        </Button>

      </CardFooter>

    </Card>
  )
}
