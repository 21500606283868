import { type ConsoleConfig } from '../../types'


export const config: ConsoleConfig = {
  baseUrl: '/',
  sidebar: {
    logoUrl: 'https://cleverev.net/assets/logo.svg',
    name: 'CleverEV'
  },
  pageTitle: 'CleverEV Console',
  sentryDsn: 'https://b20dbb121f06c120239eb57b957dc1ab@sentry.cleverev.net/5',
  connectionUrl: {
    primary: 'wss://connect.cleverev.net/',
    aliases: [
      'wss://connect.cleverev.net:443/',
      'wss://connect.cleverev.net:443/ocpp/'
    ]
  },
  legal: {
    tosLink: 'https://cleverev.net/terms/',
    eulaLink: 'https://cleverev.net/documents/eula/'
  },
  sentry: {
    org: 'comment-team',
    project: 'cleverev-console-net',
    url: 'https://sentry.cleverev.net'
  }
}
