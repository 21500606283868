import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultFlow } from './flows/DefaultFlow'
import { ExistingUserFlow } from './flows/ExistingUserFlow'


interface CreateNamespaceModalProps {
  close: () => void
}

export function CreateNamespaceModal({ close }: CreateNamespaceModalProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'namespaces.create' })
  const [ flow, setFlow ] = useState<'existing-user' | 'default'>()

  return (
    <Modal isOpen onClose={close} isCentered size="2xl">
      <ModalOverlay />

      <ModalContent>
        <ModalHeader fontSize={24}>{t('title')}</ModalHeader>
        <ModalCloseButton />

        {flow === 'existing-user' ? (
          <ExistingUserFlow close={close} />
        ) : flow === 'default' ? (
          <DefaultFlow close={close} />
        ) : (
          <ModalBody>
            <VStack gap={4}>
              <Text textAlign="center" paddingTop={8} fontSize={18}>{t('home.description')}</Text>
              <Button
                width="100%"
                onClick={() => setFlow('existing-user')}
              >
                {t('home.buttons.existing_user_flow')}
              </Button>
              <Button
                width="100%"
                onClick={() => setFlow('default')}
              >
                {t('home.buttons.default_flow')}
              </Button>
              <Button
                width="100%"
                variant="ghost"
                onClick={close}
              >
                {t('home.buttons.cancel')}
              </Button>
            </VStack>
          </ModalBody>
        )}

      </ModalContent>
    </Modal>
  )
}
