import { Tab, TabList, Tabs } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useMatches } from 'react-router-dom'
import { useApplicationAwareNavigate } from '../../../hooks/useApplicationAwareNavigate'


export function SettingsLayout() {
  const { t } = useTranslation('translation', { keyPrefix: 'settings' })
  const { navigate } = useApplicationAwareNavigate()

  // Tab list items
  const tabs = useMemo(() => [ 'account', 'authentication' ], [])

  // Find the current tab
  const matches = useMatches()
  const handle = matches[3].handle as { tab: string }

  return (
    <main>

      <Tabs
        index={tabs.indexOf(handle?.tab ?? tabs[0])}
        variant="soft-rounded"
        colorScheme="blue"
      >
        <TabList marginBottom={8}>
          <Tab onClick={() => navigate('/settings')}>{t('account.title')}</Tab>
          <Tab onClick={() => navigate('/settings/authentication')}>{t('authentication.title')}</Tab>
        </TabList>
      </Tabs>

      <Outlet />

    </main>
  )
}
