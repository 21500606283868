import { Button, Portal } from '@chakra-ui/react'
import { Fragment, ReactNode, useEffect } from 'react'
import { useNavbar } from '../hooks/useNavbar'


interface NavbarBreadcrumbButtonProps {
  children: ReactNode
  onClick?: () => void
}

export function NavbarBreadcrumbButton({ children, onClick }: NavbarBreadcrumbButtonProps) {

  const { breadcrumbRef, showBreadcrumb, setShowBreadcrumb } = useNavbar()

  useEffect(() => {
    setShowBreadcrumb(true)
    return () => {
      setShowBreadcrumb(false)
    }
  }, [ setShowBreadcrumb ])

  return (
    <Fragment>

      {showBreadcrumb && (
        <Portal containerRef={breadcrumbRef}>
          <Button variant="ghost" onClick={onClick}>
            {children}
          </Button>
        </Portal>
      )}

    </Fragment>
  )
}
