import { Editable, EditableInput, EditablePreview, Flex, IconButton, Input, Switch, Tag, Text } from '@chakra-ui/react'
import { Eye, EyeClosed } from 'iconoir-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditableControls } from './EditableControls'
import styles from './DataChunk.module.scss'


interface DataChunkProps {
  label: string
  value?: string | string[] | number | boolean
  icon?: JSX.Element
  editable?: boolean
  disabled?: boolean
  isPassword?: boolean
  onChange?: (value: string | boolean) => void
  shouldReset?: boolean
  onResetComplete?: () => void
}

export function DataChunk({
  label,
  value,
  icon,
  editable,
  onChange,
  disabled,
  isPassword,
  shouldReset,
  onResetComplete
}: DataChunkProps) {
  const { t } = useTranslation()

  const [ showPassword, setShowPassword ] = useState(false)
  const [ inputType, setInputType ] = useState(isPassword ? 'password' : 'text')
  const [ editedValue, setEditedValue ] = useState(value)

  // Function to toggle password visibility
  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
    setInputType(showPassword ? 'password' : 'text')
  }

  useEffect(() => {
    if (shouldReset) setEditedValue(value)
    onResetComplete?.()
  }, [ value, shouldReset, onResetComplete ])

  return (
    <div className={styles.dataChunkContainer}>
      {icon}
      <div className={styles.infoContainer}>
        <div className={styles.label}>{label}</div>
        <div className={styles.valueContainer}>
          {editable === true ? (
            // Editable
            typeof value === 'boolean' ? (
              // Editable boolean
              <Switch defaultChecked={value} disabled={disabled} onChange={e => onChange?.(e.target.checked)} />
            ) : (
              // Editable string/number
              <Editable
                // defaultValue={value?.toString() ?? t('charger.unavailable')}
                value={editedValue?.toString()}
                isPreviewFocusable={false}
                selectAllOnFocus={false}
                isDisabled={disabled}
                onChange={value => setEditedValue(value)}
                onSubmit={nextValue => onChange?.(nextValue)}
              >
                {({ isEditing }) => (
                  <Flex alignItems="center" gap={2}>
                    {!showPassword && !isEditing && isPassword ? (
                      <Text fontWeight="semibold" fontSize="lg">
                        {'•'.repeat((value?.toString() ?? 'password').length)}
                      </Text>
                    ) : (
                      <EditablePreview className={styles.editablePreview} />
                    )}
                    <Input type={inputType} variant="outline" as={EditableInput} disabled={disabled} />
                    {isPassword && !isEditing && (
                      <IconButton
                        aria-label={'show-password'}
                        icon={showPassword ? <EyeClosed width={20} height={20} /> : <Eye width={20} height={20} />}
                        onClick={toggleShowPassword}
                      />
                    )}
                    <EditableControls />
                  </Flex>
                )}
              </Editable>
            )
          ) : editable === false ? (
            // Not editable
            <div className={styles.valueContainer}>
              <div className={styles.value}>{value?.toString() ?? t('charger.unavailable')}</div>
              <Tag size="sm" variant="solid" colorScheme="gray">
                {t('charger.readonly').toUpperCase()}
              </Tag>
            </div>
          ) : (
            // if editable is undefined render normally
            <div className={styles.value}>{value?.toString() ?? t('charger.unavailable')}</div>
          )}
        </div>
      </div>
    </div>
  )
}
