import * as Sentry from '@sentry/react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { config } from 'cleverev-whitelabel/console/default/config'
import App from './App'
import './index.css'
import { Settings } from 'luxon'


Settings.throwOnInvalid = true

Sentry.init({ dsn: config.sentryDsn })

const root = createRoot(document.querySelector('#root') as HTMLElement)
root.render(<Sentry.ErrorBoundary><StrictMode><App /></StrictMode></Sentry.ErrorBoundary>)
