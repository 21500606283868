import { useContext } from 'react'
import { ApplicationContext } from '../contexts/ApplicationContext'


export function useApplication() {
  const context = useContext(ApplicationContext)
  if (!context) throw new Error('The useApplication() hook was used outside of ApplicationContextProvider')
  if (!context.application) throw new Error('There is no application')

  return context.application
}
