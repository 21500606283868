import { useLocation } from 'react-router-dom'


export function useApplicationAwareLocation() {

  const { pathname, ...rest } = useLocation()

  const namespaceAwarePathname = pathname.split('/').toSpliced(1, 1).join('/')

  return { ...rest, pathname: namespaceAwarePathname }
}
