import { useContext } from 'react'
import { UserContext } from '../contexts/UserContext'


export function useAuthentication() {
  const context = useContext(UserContext)
  if (!context) throw new Error('The useAuthenticated() hook was used outside of UserContextProvider.')

  return context
}
