import { Application } from '../types'
import { api, ApiResult, Pagination } from './api'


export async function listApplications(pagination: Pagination) {
  const result = await api.get<ApiResult<{ applications: Application[]; count: number }>>(`/api/hub/applications?skip=${pagination.skip}&take=${pagination.take}`)

  return result.data
}

export async function getApplication() {
  const result = await api.get<ApiResult<{ application: Application }>>('/api/hub/applications/selected')

  return result.data
}
