import { useMemo, useState } from 'react'
import type { Pagination } from '../../../api/api'
import type { PaginationState } from '../types'


export function useTablePagination(pageSize?: number) {
  // Pagination state
  const [ state, setState ] = useState<PaginationState>({ pageIndex: 0, pageSize: pageSize ?? 10 })

  const api: Pagination = useMemo(() => ({
    take: state.pageSize,
    skip: state.pageIndex * state.pageSize
  }), [ state.pageIndex, state.pageSize ])

  return { state, setState, api }
}
