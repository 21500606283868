import { LayoutProps, Th } from '@chakra-ui/react'
import { ReactNode } from 'react'


export interface TableHeadCellProps {
  isNumeric?: boolean
  isMenu?: boolean
  width?: LayoutProps['width']
  children?: ReactNode
}

export function TableHeadCell({ children, isNumeric, isMenu, width }: TableHeadCellProps) {
  return (
    <Th isNumeric={isNumeric || isMenu} width={width}>
      {children}
    </Th>
  )
}
