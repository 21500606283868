import { Center, HStack, IconButton, Text } from '@chakra-ui/react'
import { FastArrowLeft, FastArrowRight, NavArrowLeft, NavArrowRight } from 'iconoir-react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { PaginationControl } from './types'


export interface TableControlsProps {
  pagination: PaginationControl
  itemCount: number
}

export function TableControls({ pagination, itemCount }: TableControlsProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'components.table' })
  const { state: { pageIndex, pageSize }, setState: setPagination } = pagination

  // Page count and last page index helper

  const pageCount = useMemo(() => {
    return Math.ceil(itemCount / pageSize)
  }, [ itemCount, pageSize ])

  const lastPageIndex = useMemo(() => pageCount - 1, [ pageCount ])

  // Check if navigation is possible

  const canGoToPrevPage = useMemo(() => {
    return pageIndex !== 0
  }, [ pageIndex ])

  const canGoToNextPage = useMemo(() => {
    return pageIndex !== lastPageIndex
  }, [ pageIndex, lastPageIndex ])

  // Navigators to pages

  const goToFirstPage = useCallback(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [ setPagination ])

  const goToPrevPage = useCallback(() => {
    setPagination(prev => ({ ...prev, pageIndex: Math.max(prev.pageIndex - 1, 0) }))
  }, [ setPagination ])

  const goToNextPage = useCallback(() => {
    setPagination(prev => ({ ...prev, pageIndex: Math.min(prev.pageIndex + 1, lastPageIndex) }))
  }, [ lastPageIndex, setPagination ])

  const goToLastPage = useCallback(() => {
    setPagination(prev => ({ ...prev, pageIndex: lastPageIndex }))
  }, [ lastPageIndex, setPagination ])

  return (
    <Center>
      <HStack spacing={12} paddingTop={8}>
        {/* First page */}
        <IconButton
          title={t('controls.first')}
          aria-label={t('controls.first')}
          icon={<FastArrowLeft />}
          onClick={goToFirstPage}
          isDisabled={!canGoToPrevPage}
        />
        {/* Previous page */}
        <IconButton
          title={t('controls.prev')}
          aria-label={t('controls.prev')}
          icon={<NavArrowLeft />}
          onClick={goToPrevPage}
          isDisabled={!canGoToPrevPage}
        />
        {/* Page state */}
        <Text>
          {t('controls.page', {
            current: pageIndex + 1,
            count: pageCount
          })}
        </Text>
        {/* Next page */}
        <IconButton
          title={t('controls.next')}
          aria-label={t('controls.next')}
          icon={<NavArrowRight />}
          onClick={goToNextPage}
          isDisabled={!canGoToNextPage}
        />
        {/* Last page */}
        <IconButton
          title={t('controls.last')}
          aria-label={t('controls.last')}
          icon={<FastArrowRight />}
          onClick={goToLastPage}
          isDisabled={!canGoToNextPage}
        />
      </HStack>
    </Center>
  )
}
