import { useContext } from 'react'
import { NavbarContext } from '../contexts/NavbarContext'


export function useNavbar() {
  const context = useContext(NavbarContext)
  if (!context) throw new Error('The useNavbar() hook was used outside of the NavbarContextProvider.')

  return context
}
