import { Tooltip, useClipboard } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import classes from './Copyable.module.scss'


interface CopyableProps {
  value: string
  display?: string
}

export function Copyable(props: CopyableProps) {
  const { t } = useTranslation()
  const { onCopy, hasCopied } = useClipboard(props.value)

  return (
    <Tooltip
      label={hasCopied ? t('components.copyable.copied') : t('components.copyable.hint')}
      isOpen={hasCopied ? true : undefined}
    >
      <span onClick={onCopy} className={classes.copyable}>
        {props.display ?? props.value}
      </span>
    </Tooltip>
  )
}
