import { MenuItem, MenuList } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { useCallback, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Eye, WarningHexagon } from 'iconoir-react'
import { Copyable } from '../../../../components/Copyable'
import { TableRow, TableRowCell, TableRowMenu } from '../../../../components/Table'
import { Namespace } from '../../../../types'
import { suspendNamespace } from '../../../../api/namespaces'
import { useToast } from '../../../../hooks/useToast'
import { useApplicationAwareNavigate } from '../../../../hooks/useApplicationAwareNavigate'


export function NamespacesTableRow({ namespace }: { namespace: Namespace }) {
  const { t } = useTranslation('translation', { keyPrefix: 'namespaces' })
  const { navigate } = useApplicationAwareNavigate()
  const queryClient = useQueryClient()
  const toast = useToast()

  const suspendNamespaceMutation = useMutation({
    mutationKey: [ 'namespace', namespace.id, 'suspend' ],
    mutationFn: () => suspendNamespace(namespace.id),
    onMutate: () => {
      queryClient.invalidateQueries({ queryKey: [ 'namespaces' ] })
    },
    onSuccess: () => {
      toast.success(t('suspend.feedback.success', namespace))
    },
    onError: () => {
      toast.error(t('suspend.feedback.failure', namespace))
    }
  })

  const onClickView = useCallback(() => {
    navigate(`/namespaces/${namespace.id}`)
  }, [ navigate, namespace.id ])

  return (
    <Fragment>
      <TableRow>
        <TableRowCell>{namespace.name}</TableRowCell>
        <TableRowCell>
          <Copyable value={namespace.id} />
        </TableRowCell>
        <TableRowCell>{namespace?.properties?.currency}</TableRowCell>
        <TableRowCell>{DateTime.fromISO(namespace.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}</TableRowCell>
        <TableRowCell>{DateTime.fromISO(namespace.updatedAt).toLocaleString(DateTime.DATETIME_SHORT)}</TableRowCell>
        <TableRowMenu label={'Menu'} >
          <MenuList>
            {/* TODO fix url in navigate() call */}
            <MenuItem icon={<Eye />} onClick={onClickView}>{t('menu.view')}</MenuItem>
            {/* TODO add a confirmation modal */}
            <MenuItem icon={<WarningHexagon />} onClick={() => suspendNamespaceMutation.mutate()}>{t('menu.suspend')}</MenuItem>
          </MenuList>
        </TableRowMenu>
      </TableRow>
    </Fragment>
  )
}
