import { Card, CardBody, CardHeader, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useTitle } from '../../hooks'


export default function SupportScreen() {
  const { t } = useTranslation()

  // Document title
  useTitle(t('pages.support'))

  return (
    <main>

      <Card>
        <CardHeader>
          <Heading size="md">{t('pages.support')}</Heading>
        </CardHeader>
        <CardBody>
          <Text>{t('general.wip')}</Text>
        </CardBody>
      </Card>

    </main>
  )
}
