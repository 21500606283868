import { useQuery, useQueryClient } from '@tanstack/react-query'
import { createContext, ReactNode, useCallback } from 'react'
import { getSelf } from '../api/users'
import { type User } from '../types'


interface IUserContext {
  isLoading: boolean
  isAuthenticated: boolean
  user?: User
  signInAs: (user: User) => void
  signOut: () => void
}

export const UserContext = createContext<IUserContext | null>(null)

export function UserContextProvider({ children }: { children: ReactNode }) {
  const queryClient = useQueryClient()

  // Fetch the user
  const userQuery = useQuery({
    queryKey: [ 'self' ],
    queryFn: () => getSelf(),
    staleTime: Number.POSITIVE_INFINITY,
    retry: false
  })

  const signInAs = useCallback((user: User) => {
    queryClient.setQueryData([ 'self' ], { user })
  }, [ queryClient ])

  const signOut = useCallback(() => {
    queryClient.setQueryData([ 'self' ], { user: undefined })
  }, [ queryClient ])

  return (
    <UserContext.Provider value={{
      isLoading: userQuery.isPending,
      isAuthenticated: !!userQuery.data?.user,
      user: userQuery.data?.user,
      signInAs,
      signOut
    }}>
      {children}
    </UserContext.Provider>
  )
}
