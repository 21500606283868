import { ModalBody } from '@chakra-ui/react'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ControlledStepper } from '../../../../../components/ControlledStepper'
import { StepCreateNamespace } from '../steps/StepCreateNamespace'
import { StepAssignUser } from '../steps/StepAssignUser'


interface DefaultFlowProps {
  close: () => void
}

export function ExistingUserFlow({ close }: DefaultFlowProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'namespaces.create' })

  const [ step, setStep ] = useState(1)
  const [ namespaceId, setNamespaceId ] = useState<string>()

  const steps = useMemo(() => [
    { title: t('steps.create_namespace.title') },
    { title: t('steps.assign_user.title') }
  ], [ t ])

  const goToStepTwo = useCallback((namespaceId: string) => {
    setNamespaceId(namespaceId)
    setStep(2)
  }, [])

  return (
    <Fragment>
      <ModalBody marginY={4}>
        <ControlledStepper steps={steps} activeStep={step - 1} />
      </ModalBody>

      {step === 1 && (
        <StepCreateNamespace
          next={goToStepTwo}
          close={close}
        />
      )}

      {step === 2 && namespaceId && (
        <StepAssignUser
          namespaceId={namespaceId}
          close={close}
        />
      )}

    </Fragment>
  )
}
