import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../../../../hooks/useUser'


export function ProfileSection() {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.account.profile' })
  const user = useUser()

  return (
    <Card>

      <CardHeader>
        <Heading size="md">{t('title')}</Heading>
      </CardHeader>

      <CardBody as={Flex} flexDirection="column" gap={4} maxWidth="50%">

        {/* EMAIL */}

        <FormControl>
          <FormLabel htmlFor="email">{t('fields.email.label')}</FormLabel>
          <Input
            id="email"
            type="email"
            autoComplete="email"
            value={user.email}
            isReadOnly
            maxWidth={450}
          />
        </FormControl>

        {/* FULL NAME */}

        <FormControl>
          <FormLabel htmlFor="fullname">{t('fields.fullname.label')}</FormLabel>
          <Input
            id="fullname"
            type="text"
            autoComplete="name"
            value={user.properties.fullname}
            isReadOnly
            maxWidth={450}
          />
        </FormControl>

      </CardBody>

    </Card>
  )
}
