import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { LogOut, User } from 'iconoir-react'
import { useTranslation } from 'react-i18next'
import { signout } from '../../../api/users'
import { useApplicationAwareNavigate } from '../../../hooks/useApplicationAwareNavigate'
import { useUser } from '../../../hooks/useUser'
import { useAuthentication } from '../../../hooks/useAuthentication'


export function UserMenu() {
  const { t } = useTranslation('translation', { keyPrefix: 'navbar' })
  const { navigate } = useApplicationAwareNavigate()
  const { signOut } = useAuthentication()
  const { properties: { fullname } } = useUser()

  // Sign out mutation
  const signOutMutation = useMutation({
    mutationKey: [ 'signout' ],
    mutationFn: () => signout(),
    onSuccess: () => signOut()
  })

  return (
    <Menu isLazy placement="bottom-end">
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        leftIcon={<User />}
        variant="ghost"
      >
        {fullname}
      </MenuButton>
      <MenuList zIndex="dropdown">
        <MenuItem
          icon={<User />}
          onClick={() => navigate('/settings')}
        >
          {t('user_dropdown.view_account')}
        </MenuItem>
        <MenuItem
          icon={<LogOut />}
          onClick={() => signOutMutation.mutate()}
        >
          {t('user_dropdown.sign_out')}
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
