import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTitle } from '../../hooks'
import { NamespacesTable } from './components'
import { NavbarEndButton } from '../../components/NavbarEndButton'
import { AddIcon } from '@chakra-ui/icons'
import { useCallback } from 'react'
import { CreateNamespaceModal } from './modals/create-namespace'


export default function NamespacesScreen() {
  const { t } = useTranslation()
  const [ isCreateNamespaceModalOpen, setIsCreateNamespaceModalOpen ] = useState(false)

  const createNamespaceHandler = useCallback(() => {
    setIsCreateNamespaceModalOpen(true)
  }, [ setIsCreateNamespaceModalOpen ])

  // Document title
  useTitle(t('pages.namespaces'))

  return (
    <main>
      <NavbarEndButton
        leftIcon={<AddIcon />}
        onClick={createNamespaceHandler}
      >
        {t('namespaces.create.button')}
      </NavbarEndButton>

      <NamespacesTable />

      {isCreateNamespaceModalOpen && (
        <CreateNamespaceModal close={() => setIsCreateNamespaceModalOpen(false)} />
      )}
    </main>
  )
}
