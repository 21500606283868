import { Heading, Image, Spacer, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { NavItem } from './NavigationItem'
import styles from './Sidebar.module.scss'
import logo from 'cleverev-whitelabel/hub/default/assets/Logo_color.svg'
import { ChatBubbleEmpty, CreditCard, Lock, Server, StatsUpSquare, Tournament } from 'iconoir-react'


export default function Sidebar() {
  const { t } = useTranslation()

  return (
    <aside className={styles.sidebar}>

      <div className={styles.logo}>
        <Image src={logo} alt={'CleverEV'} /> {/* //TODO Whitelabel */}
        <Heading size="md" noOfLines={1} color="#374151">CleverEV Hub</Heading>
      </div>

      <VStack as="nav" alignItems="stretch">

        {/* Namespaces */}

        <NavItem
          icon={<Tournament />}
          route='/'
          title={t('pages.namespaces')}
        />

        <Spacer padding={1} />

        {/* Billing */}

        <NavItem
          icon={<CreditCard />}
          route='/billing'
          title={t('pages.billing')}
        />

        {/* Support */}

        <NavItem
          icon={<ChatBubbleEmpty />}
          route='/support'
          title={t('pages.support')}
        />

        <Spacer padding={1} />

        {/* Access */}

        <NavItem
          icon={<Lock />}
          route='/access'
          title={t('pages.access')}
        />

        {/* API */}

        <NavItem
          icon={<Server />}
          route='/api'
          title={t('pages.api')}
        />

        {/* Diagostics */}

        <NavItem
          icon={<StatsUpSquare />}
          route='/diagnostics'
          title={t('pages.diagnostics')}
        />
      </VStack>

    </aside>
  )
}
