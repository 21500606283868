import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { IconoirProvider } from 'iconoir-react'
import { StrictMode } from 'react'
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom'
import NavBar from './components/NavBar/Navbar'
import Sidebar from './components/Sidebar/Sidebar'
import { AlertContextProvider } from './contexts/AlertContext'
import { ApplicationContextProvider } from './contexts/ApplicationContext'
import { NavbarContextProvider } from './contexts/NavbarContext'
import { UserContextProvider } from './contexts/UserContext'
import { useAuthentication } from './hooks/useAuthentication'
import NotFoundScreen from './screens/404/NotFoundScreen'
import AccessScreen from './screens/Access/AccessScreen'
import BillingScreen from './screens/Billing/BillingScreen'
import NamespacesScreen from './screens/Namespaces/NamespacesScreen'
import SupportScreen from './screens/Support/SupportScreen'
import VerifyAccount from './screens/VerifyAccount/VerifyAccount'
import { theme } from './theme/theme'
import './utils/i18n'
// eslint-disable-next-line case-police/string-check
import ApiScreen from './screens/Api/ApiScreen'
import DiagnosticsScreen from './screens/Diagnostics/DiagnosticsScreen'
import { SettingsLayout } from './screens/settings/layout/SettingsLayout'
import { AccountScreen } from './screens/settings/screens/account'
import { AuthenticationScreen } from './screens/settings/screens/authentication'
import { UnauthenticatedLayout } from './screens/unauthenticated/layout'
import { SignInScreen } from './screens/unauthenticated/screens/sign-in'
import { NamespaceDetailsScreen } from './screens/NamespaceDetails/NamespaceDetails'
import { useApplication } from './hooks/useApplication'


const iconProps = {
  strokeWidth: 1.5
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000 // 1 minute
    }
  }
})

const unauthenticatedRouter = createBrowserRouter([
  {
    path: '/',
    element: <UnauthenticatedLayout />,
    children: [
      {
        index: true,
        element: <SignInScreen />
      },
      {
        path: '*',
        element: <Navigate to="/" replace />
      }
    ]
  }
])

function AuthenticatedLayout() {
  return (
    <ApplicationContextProvider>
      <AlertContextProvider>
        <NavbarContextProvider>
          <div id="screen">
            <NavBar />
            <Sidebar />
            <Outlet />
          </div>
        </NavbarContextProvider>
      </AlertContextProvider>
    </ApplicationContextProvider>
  )
}

function RedirectToNamespaces() {
  const application = useApplication()

  return (
    <Navigate to={`/${application.id}/namespaces`} replace />
  )
}

const authenticatedRouter = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        path: ':applicationId',
        element: <AuthenticatedLayout />,
        children: [
          {
            // index: true,
            path: 'namespaces',
            element: <NamespacesScreen />
          },
          {
            path: 'namespaces/:namespaceId',
            element: <NamespaceDetailsScreen />
          },
          {
            path: 'billing',
            element: <BillingScreen />
          },
          {
            path: 'support',
            element: <SupportScreen />
          },
          {
            path: 'access',
            element: <AccessScreen />
          },
          {
            path: 'api',
            element: <ApiScreen />
          },
          {
            path: 'diagnostics',
            element: <DiagnosticsScreen />
          },
          {
            path: 'verify-account',
            element: <VerifyAccount />
          },
          {
            path: 'settings',
            element: <SettingsLayout />,
            children: [
              {
                index: true,
                element: <AccountScreen />,
                handle: { tab: 'account' }
              },
              {
                path: 'authentication',
                element: <AuthenticationScreen />,
                handle: { tab: 'authentication' }
              }
            ]
          },
          {
            path: '*',
            element: <NotFoundScreen />
          }
        ]
      },
      {
        path: '/',
        element: <ApplicationContextProvider><RedirectToNamespaces /></ApplicationContextProvider>
      }
    ]
  }
])

function Navigation() {
  const { isLoading, isAuthenticated } = useAuthentication()
  if (isLoading) return null

  return isAuthenticated
    ? <RouterProvider router={authenticatedRouter} />
    : <RouterProvider router={unauthenticatedRouter} />
}

export default function App() {
  return (
    <StrictMode>
      <ChakraProvider theme={theme}>
        <IconoirProvider iconProps={iconProps}>
          <QueryClientProvider client={queryClient}>
            <UserContextProvider>
              <Navigation />
            </UserContextProvider>
          </QueryClientProvider>
        </IconoirProvider>
      </ChakraProvider>
    </StrictMode>
  )
}
