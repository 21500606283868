import { Spinner } from '@chakra-ui/react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { verifyEmail } from '../../api/users'
import { useApplicationAwareNavigate } from '../../hooks/useApplicationAwareNavigate'
import { useTitle } from '../../hooks/useTitle'
import { useToast } from '../../hooks/useToast'
import { useUser } from '../../hooks/useUser'


export default function VerifyAccountScreen() {
  const { t } = useTranslation()
  useTitle(t('pages.verifyAccount'))

  const [ searchParams ] = useSearchParams()
  // Retrieve verification token
  const token = searchParams.get('token')
  const user = useUser()
  const { navigate } = useApplicationAwareNavigate()
  const toast = useToast()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (user.emailVerified) navigate('/')
  }, [ user.emailVerified, navigate ])

  const { isSuccess, isError, isFetching } = useQuery({
    queryKey: [ 'verify-account' ],
    queryFn: () => verifyEmail(token ?? ''),
    enabled: !user.emailVerified,
    retry: false
  })

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('account.verification.toast.success'))
      queryClient.invalidateQueries({
        queryKey: [ 'self' ]
      })
      navigate('/')
    } else if (isError) {
      toast.error(t('account.verification.toast.error'), t('account.verification.toast.tip'))
      navigate('/')
    }
  }, [ isSuccess, isError, navigate, toast, queryClient, t ])

  return (
    <main>
      {isFetching && <Spinner size='sm' />}
    </main>
  )
}
