import { Td } from '@chakra-ui/react'
import { ReactNode } from 'react'


export interface TableRowCellProps {
  isNumeric?: boolean
  children: ReactNode
}

export function TableRowCell({ children, isNumeric }: TableRowCellProps) {
  return (
    <Td
      isNumeric={isNumeric}
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
    >
      {children}
    </Td>
  )
}
