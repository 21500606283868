import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useTitle } from '../../../../hooks/useTitle'
import { ChangePasswordSection } from './sections/ChangePasswordSection'


export function AuthenticationScreen() {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.authentication' })

  // Document title
  useTitle(t('title'))

  return (
    <Flex gap={8} direction="column">

      <ChangePasswordSection />

    </Flex>
  )
}
