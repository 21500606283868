import { Breadcrumb, BreadcrumbItem, Button } from '@chakra-ui/react'
import { NavArrowRight } from 'iconoir-react'
import { useTranslation } from 'react-i18next'
import { useApplicationAwareLocation } from '../../hooks/useApplicationAwareLocation'
import { useApplicationAwareNavigate } from '../../hooks/useApplicationAwareNavigate'
import { useNavbar } from '../../hooks/useNavbar'
import { UserMenu } from './components/UserMenu'
import styles from './Navbar.module.scss'
import { SelectApplications } from './components/SelectApplications'


export default function NavBar() {
  const { t } = useTranslation()
  const { navigate } = useApplicationAwareNavigate()
  const { pathname } = useApplicationAwareLocation() // useLocation()

  // Navbar state
  const { navbarRef, breadcrumbRef, showBreadcrumb } = useNavbar()
  const path = `/${pathname.split('/')[1]}`

  return (
    <nav className={styles.navbar}>

      <Breadcrumb
        flexGrow={1}
        separator={<NavArrowRight width={24} height={24} color="#374151" />}
      >
        <BreadcrumbItem>
          <SelectApplications />
        </BreadcrumbItem>

        <BreadcrumbItem>
          <Button onClick={() => navigate(path)} variant="ghost">
            {(pathname === '/' || pathname === '') ? t('pages.namespaces') : t(`pages.${path.slice(1)}`)}
          </Button>
        </BreadcrumbItem>

        {showBreadcrumb && (
          <BreadcrumbItem ref={breadcrumbRef} />
        )}

      </Breadcrumb>

      <div ref={navbarRef} />

      <UserMenu />

    </nav>
  )
}
