import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { FormEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { requestDeletion } from '../../../../../api/users'
import { useAuthentication } from '../../../../../hooks/useAuthentication'
import { Alert } from '../../../../../components/Feedback'
import { useFormField } from '../../../../../hooks/useFormField'


export function DeleteAccountSection() {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.account.delete' })
  const { signOut } = useAuthentication()

  // Current password form field
  const currentPasswordField = useFormField({
    initialValue: '',
    schema: z.string()
      .min(1, t('fields.current_password.error.required'))
  })

  // Request account deletion mutation
  const mutation = useMutation({
    mutationKey: [ 'user', 'request-deletion' ],
    mutationFn: () => requestDeletion(currentPasswordField.value),
    onSuccess: () => signOut()
  })

  // Handle submit
  const handleSubmit = useCallback((event: FormEvent) => {
    event.preventDefault()
    mutation.mutate()
  }, [ mutation ])

  return (
    <Card as="form" onSubmit={handleSubmit}>

      <CardHeader as={Flex} flexDirection="column" gap={4}>
        <Heading size="md">{t('title')}</Heading>

        <Text>{t('description')}</Text>
      </CardHeader>

      <CardBody as={Flex} flexDirection="column" gap={4} maxW="50%">

        {mutation.isError && (
          <Alert status="error" description={t('feedback.failure')} />
        )}

        {/* CURRENT PASSWORD */}

        <FormControl isRequired isInvalid={currentPasswordField.shouldShowError}>
          <FormLabel htmlFor="current-password">{t('fields.current_password.label')}</FormLabel>
          <Input
            id="current-password"
            type="password"
            autoComplete="current-password"
            value={currentPasswordField.value}
            onChange={e => currentPasswordField.setValue(e.target.value)}
            onBlur={currentPasswordField.handleBlur}
            maxWidth={450}
          />
          <FormErrorMessage>{currentPasswordField.error}</FormErrorMessage>
        </FormControl>

      </CardBody>

      <CardFooter>

        <Button
          type="submit"
          colorScheme="red"
          isLoading={mutation.isPending}
          isDisabled={currentPasswordField.isInvalid}
          loadingText={t('buttons.submit.pending')}
        >
          {t('buttons.submit.idle')}
        </Button>

      </CardFooter>

    </Card>
  )
}
