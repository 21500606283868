import { Button, ButtonProps, Portal } from '@chakra-ui/react'
import { useNavbar } from '../hooks/useNavbar'


export function NavbarEndButton(props: ButtonProps) {
  const { navbarRef } = useNavbar()

  return (
    <Portal containerRef={navbarRef}>
      <Button variant="ghost" {...props} />
    </Portal>
  )
}
