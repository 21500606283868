import { Tr } from '@chakra-ui/react'
import { ReactNode } from 'react'


export interface TableRowProps {
  children: ReactNode
  isDisabled?: boolean
}

export function TableRow({ children, isDisabled }: TableRowProps) {
  return (
    <Tr style={isDisabled ? { opacity: 0.5, pointerEvents: 'none' } : undefined}>
      {children}
    </Tr>
  )
}
