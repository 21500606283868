import { useCallback, useMemo, useRef, useState } from 'react'
import { ZodSchema } from 'zod'


interface FormField<T> {
  value: T
  setValue: (value: T) => void
  schema: ZodSchema
}

interface FormField2<T> {
  initialValue: T
  schema: ZodSchema
}

// A form helper utility to reduce boilerplate in most forms
export function useFormField<T>(formField: FormField<T> | FormField2<T>) {

  const initialValue = useRef('initialValue' in formField ? formField.initialValue : formField.value)

  const [ state, setState ] = useState('initialValue' in formField ? formField.initialValue : undefined)

  const value = useMemo(() => {
    return 'value' in formField ? formField.value : state as T
  }, [ formField, state ])

  const setValue = useCallback((val: T) => {
    'setValue' in formField ? formField.setValue(val) : setState(val)
  }, [ formField ])

  const [ isTouched, setIsTouched ] = useState(false)

  const error = useMemo(() => {
    const result = formField.schema.safeParse(value)
    if (!result.success) return result.error.errors[0].message
  }, [ formField.schema, value ])

  const reset = useCallback(() => {
    setValue(initialValue.current)
    setIsTouched(false)
  }, [ setValue ])

  return {
    value,
    setValue,
    handleBlur: () => setIsTouched(true),
    isInvalid: !!error,
    isTouched,
    shouldShowError: isTouched && !!error,
    error: isTouched ? error : undefined,
    reset
  }
}
