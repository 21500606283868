import axios from 'axios'
import { config } from 'cleverev-whitelabel/console/default/config'


export type ApiResult<T extends object = Record<never, never>> = { success: boolean; message?: string } & T

// Read the base URL from the environment variables or use the production backend
const baseURL = process.env.NODE_ENV === 'production' ? config.baseUrl : 'http://localhost:3001'

// Create axios instance
const api = axios.create({
  baseURL,
  withCredentials: true
})

// Set the selected application id on the request headers
api.interceptors.request.use(config => {
  const currentApplicationId = location.pathname.split('/')[1]
  const specificApplicationId = config.headers.get('x-application-id') ?? undefined
  const specifiesApplicationId = specificApplicationId !== undefined
  if (currentApplicationId && !specifiesApplicationId) config.headers.set('x-application-id', currentApplicationId)

  return config
})

export { api }

export interface Pagination {
  take: number
  skip?: number
}
