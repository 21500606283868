import {
  Button,
  ModalBody,
  ModalFooter,
  Flex,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage
} from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { createNamespace } from '../../../../../api/namespaces'
import { Alert } from '../../../../../components/Feedback'
import { useUser } from '../../../../../hooks/useUser'


interface StepCreateNamespaceProps {
  next: (namespaceId: string) => void
  close: () => void
}

export function StepCreateNamespace({ next, close }: StepCreateNamespaceProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'namespaces.create.steps.create_namespace' })
  const queryClient = useQueryClient()
  const user = useUser()

  const [ name, setName ] = useState('')

  const [ isNameTouched, setIsNameTouched ] = useState(false)

  const nameError = useMemo(() => {
    const schema = z.string()
      .min(1, t('name.errors.required'))
      .max(64, t('name.errors.too-long', { max: 64 }))
    const result = schema.safeParse(name)
    if (!result.success) return result.error.errors[0].message
  }, [ name, t ])

  // TODO CURRENCY
  const createNamespaceMutation = useMutation({
    mutationKey: [ 'namespaces', 'create' ],
    mutationFn: () => createNamespace(name, { userId: user.id }),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [ 'namespaces', 'list' ] })
      next(data.namespaceId)
    }
  })

  return (
    <Fragment>
      <ModalBody as={Flex} direction="column" gap={8}>

        {createNamespaceMutation.isError && (
          <Alert status="error" description={t('alert')} />
        )}

        <FormControl isInvalid={isNameTouched && !!nameError} isRequired>
          <FormLabel>{t('name.label')}</FormLabel>
          <Input
            value={name}
            onChange={e => setName(e.target.value)}
            onBlur={() => setIsNameTouched(true)}
            placeholder={t('name.placeholder')}
          />
          {nameError && (
            <FormErrorMessage>{nameError}</FormErrorMessage>
          )}
          <FormHelperText>{t('name.description')}</FormHelperText>
        </FormControl>

        {/*
        <FormControl isRequired>
          <FormLabel>{t('type.label')}</FormLabel>
          <Select
            value={type}
            onChange={setType}
            options={Object.values(ChargerType).map(value => ({
              value,
              display: t(`type.enum.${value}`)
            }))}
            width="100%"
          />
          <FormHelperText>{t('type.description')}</FormHelperText>
        </FormControl>
        */}

      </ModalBody>
      <ModalFooter gap={4}>
        <Button onClick={close} variant="ghost">
          {t('buttons.cancel')}
        </Button>
        <Button
          onClick={() => createNamespaceMutation.mutate()}
          isDisabled={!!nameError}
          isLoading={createNamespaceMutation.isPending}
          loadingText={t('buttons.next')}
          colorScheme="blue"
        >
          {t('buttons.next')}
        </Button>
      </ModalFooter>
    </Fragment>
  )
}
