import { Alert as AlertContainer, AlertDescription, AlertIcon, AlertStatus, AlertTitle } from '@chakra-ui/react'


interface AlertProps {
  status: AlertStatus
  title?: string
  description?: string
}

export function Alert(props: AlertProps) {
  return (
    <AlertContainer status={props.status} borderRadius={4}>
      <AlertIcon />
      {props.title && (
        <AlertTitle>{props.title}</AlertTitle>
      )}
      {props.description && (
        <AlertDescription>{props.description}</AlertDescription>
      )}
    </AlertContainer>
  )
}
