import { Flex, Spinner } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getNamespace } from '../../api/namespaces'
import { useTitle } from '../../hooks/useTitle'
import { OverviewCard } from './cards/OverviewCard'
import { NavbarBreadcrumbButton } from '../../components/NavbarBreadcrumbButton'


export function NamespaceDetailsScreen() {
  const { t } = useTranslation()
  const { namespaceId } = useParams()

  useTitle(t('pages.namespaces'))

  const getNamespaceQuery = useQuery({
    queryKey: [ 'namespaces', namespaceId ],
    queryFn: () => getNamespace(namespaceId as string)
  })

  return (
    <main>
      {!getNamespaceQuery.data && <Spinner /> }
      {getNamespaceQuery.data && (
        <Fragment>
          <NavbarBreadcrumbButton>
            {getNamespaceQuery.data.namespace.name}
          </NavbarBreadcrumbButton>
          <Flex gap={8} direction="column">
            <OverviewCard namespace={getNamespaceQuery.data.namespace} />
          </Flex>
        </Fragment>
      )}
    </main>
  )
}
