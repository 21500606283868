import { ReactNode, createContext, useRef, MutableRefObject, useState } from 'react'


// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ElementRef = MutableRefObject<any>

interface INavbarContext {
  navbarRef: ElementRef
  breadcrumbRef: ElementRef
  secondaryRef: ElementRef
  showNavbar: boolean
  setShowNavbar: (show: boolean) => void
  showBreadcrumb: boolean
  setShowBreadcrumb: (show: boolean) => void
  showSecondary: boolean
  setShowSecondary: (show: boolean) => void
}

export const NavbarContext = createContext<INavbarContext | null>(null)

export function NavbarContextProvider({ children }: { children: ReactNode }) {
  const navbarRef = useRef(null)
  const breadcrumbRef = useRef(null)
  const secondaryRef = useRef(null)

  const [ showNavbar, setShowNavbar ] = useState(false)
  const [ showBreadcrumb, setShowBreadcrumb ] = useState(false)
  const [ showSecondary, setShowSecondary ] = useState(false)

  return (
    <NavbarContext.Provider value={{
      navbarRef,
      breadcrumbRef,
      secondaryRef,
      showNavbar,
      setShowNavbar,
      showBreadcrumb,
      setShowBreadcrumb,
      showSecondary,
      setShowSecondary
    }}>
      {children}
    </NavbarContext.Provider>
  )
}
