import { Flex } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'


export function UnauthenticatedLayout() {
  return (
    <Flex minHeight="100%" align="center" justify="center" bg="bg.screen">

      <Outlet />

    </Flex>
  )
}
