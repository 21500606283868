import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay, Button, useDisclosure
} from '@chakra-ui/react'
import { createContext, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'


interface Alert {
  title: string
  description?: string
  destructive?: boolean
  actionHandler?: () => void
  actionText: string
}

interface IAlertContext {
  show: (alert: Alert) => void
}

export const AlertContext = createContext<IAlertContext | null>(null)

export function AlertContextProvider({ children }: { children: ReactNode }) {
  const { t } = useTranslation()

  // States and refs for the dialog
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [ alert, setAlert ] = useState<Alert | null>(null)
  const cancelRef = useRef(null)

  // Set the alert's data
  const show = useCallback((alert: Alert) => {
    setAlert(alert)
  }, [])

  // Open the dialog when the alert data is set
  useEffect(() => {
    if (alert) onOpen()
  }, [ alert, onOpen ])

  // Reset alert data when the dialog is closed
  useEffect(() => {
    if (!isOpen) setAlert(null)
  }, [ isOpen ])

  // Handle clicking the action button
  const onClickAction = useCallback(() => {
    if (alert?.actionHandler) alert.actionHandler()
    onClose()
  }, [ alert, onClose ])

  return (
    <AlertContext.Provider value={{ show }}>
      {children}

      {alert && (
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                {alert.title}
              </AlertDialogHeader>

              {alert.description && (
                <AlertDialogBody>
                  {alert.description}
                </AlertDialogBody>
              )}

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose} variant="ghost">
                  {t('general.cancel')}
                </Button>
                <Button colorScheme={alert.destructive ? 'red' : 'blue'} onClick={onClickAction} ml={3}>
                  {alert.actionText ?? t('general.ok')}
                </Button>
              </AlertDialogFooter>

            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

    </AlertContext.Provider>
  )
}
