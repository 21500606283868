import { IconButton, Menu, MenuButton, MenuListProps, Td } from '@chakra-ui/react'
import { MoreVert } from 'iconoir-react'
import { ReactElement } from 'react'


export interface TableRowMenuProps {
  label: string
  children: ReactElement<MenuListProps>
}

export function TableRowMenu({ children, label }: TableRowMenuProps) {
  return (
    <Td isNumeric>
      <Menu>
        <MenuButton
          title={label}
          aria-label={label}
          as={IconButton}
          icon={<MoreVert strokeWidth={3} />}
          variant="ghost"
        />
        {children}
      </Menu>
    </Td>
  )
}
