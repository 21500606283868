import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react'
import { Calendar, CalendarPlus, Computer, Cpu, Database, UserSquare } from 'iconoir-react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { Namespace } from '../../../types'
import { DataChunk } from '../../../components/DataChunk'


export interface OverviewCardProps {
  namespace: Namespace
}

export function OverviewCard({ namespace }: OverviewCardProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'namespace.information.overview' })

  return (
    <Card width="100%">
      <CardHeader>
        <Heading size="md">
          {t('title')}
        </Heading>
      </CardHeader>
      <CardBody>
        <DataChunk
          label={t('id')}
          value={namespace.id}
          icon={<Cpu width={32} height={32} />}
        />
        <DataChunk
          label={t('name')}
          value={namespace.name}
          icon={<UserSquare width={32} height={32} />}
        />
        <DataChunk
          label={t('project_id')}
          value={namespace.projectId}
          icon={<Database width={32} height={32} />}
        />
        <DataChunk
          label={t('application_id')}
          value={namespace.applicationId}
          icon={<Computer width={32} height={32} />}
        />
        <DataChunk
          label={t('owner_id')}
          value={namespace.properties.userId}
          icon={<UserSquare width={32} height={32} />}
        />
        <DataChunk
          label={t('created_at')}
          value={DateTime.fromISO(namespace.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}
          icon={<Calendar width={32} height={32} />}
        />
        <DataChunk
          label={t('updated_at')}
          value={DateTime.fromISO(namespace.updatedAt).toLocaleString(DateTime.DATETIME_SHORT)}
          icon={<CalendarPlus width={32} height={32} />}
        />
      </CardBody>
    </Card>
  )
}
